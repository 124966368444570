import { EnvEnum } from "@superblocksteam/shared";
import envs from "../env";

export const isDev = (): boolean => {
  return (
    envs.get("SUPERBLOCKS_UI_ENV") === EnvEnum.DEV ||
    envs.get("SUPERBLOCKS_UI_ENV") === EnvEnum.LOCAL
  );
};

export const isProduction = (): boolean => {
  return envs.get("SUPERBLOCKS_UI_ENV") === EnvEnum.PROD;
};
