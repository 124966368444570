import { datadogLogs } from "@datadog/browser-logs";
import {
  AgentDiagnosticMetadata,
  UIDiagnosticMetadata,
  UIErrorPriorityMap,
  UIErrorType,
} from "@superblocksteam/shared";
import _ from "lodash";
import { UIEvent } from "./event";

const logger = datadogLogs.logger;
/*
The replacer parameter allows you to specify a function that replaces values with your own. We can use it to control what gets stringified.
*/
function replaceError(key: string, value: any) {
  if (value instanceof Error) {
    const newValue = Object.getOwnPropertyNames(value).reduce(
      (obj, propName) => {
        obj[propName] = (value as any)[propName];
        return obj;
      },
      { name: value.name } as any,
    );
    return newValue;
  } else {
    return value;
  }
}

export const stringifyError = (error: any) => {
  return JSON.stringify(error, replaceError, 2);
};

export const getUIErrorTypeByCode = (code: number) => {
  let errorType = UIErrorType.SERVER_ERROR_OTHER;
  if (code >= 500) {
    errorType = UIErrorType.SERVER_ERROR_5XX;
  } else if (code >= 400) {
    errorType = UIErrorType.SERVER_ERROR_4XX;
  }
  return errorType;
};
class Logger {
  private static _instance: Logger;

  private constructor() {
    this.setUIDiagnosticContext(this.uiDiagnosticContext);
  }
  private uiDiagnosticContext: UIDiagnosticMetadata = {};
  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  debug(message: any, messageContext = {}, sendToDdog = true): void {
    console.debug(message);
    if (!sendToDdog) {
      return;
    }
    if (_.isObject(message)) {
      message = JSON.stringify(message);
    }
    logger.debug(message, messageContext);
  }

  info(message: any, messageContext = {}): void {
    console.info(message);
    if (_.isObject(message)) {
      message = JSON.stringify(message);
    }
    logger.info(message, messageContext);
  }

  warn(message: any, messageContext = {}): void {
    console.warn(message);
    if (_.isObject(message)) {
      message = JSON.stringify(message);
    }
    logger.warn(message, messageContext);
  }

  error(
    message: any,
    messageContext?: AgentDiagnosticMetadata & UIDiagnosticMetadata,
  ): void {
    console.error(message);
    if (_.isObject(message)) {
      message = JSON.stringify(message);
    }
    let messageContextToSend = messageContext;

    messageContextToSend = {
      ...this.uiDiagnosticContext,
      ...messageContext,
    };
    if (messageContext?.superblocks_ui_error_type) {
      messageContextToSend.superblocks_ui_error_priority =
        UIErrorPriorityMap[messageContext.superblocks_ui_error_type];
    }

    logger.error(message, messageContextToSend);
  }

  /**
   * @deprecated TODO: (@joeyagreco) configure this to use the same flow as the CloudEventSender in the server
   */
  event(type: UIEvent, properties = {}): void {
    console.debug("skipping sending event");
    // NOTE: (@joeyagreco) here is how the event was being sent to rockset previously:
    // EventSender.send({
    //   userId: this.uiDiagnosticContext.superblocks_user_id,
    //   email: this.uiDiagnosticContext.superblocks_user_email,
    //   organizationId: this.uiDiagnosticContext.superblocks_org_id,
    //   type: type,
    //   createdAt: new Date(),
    //   properties: {
    //     actionName: this.uiDiagnosticContext.superblocks_action_name,
    //     actionId: this.uiDiagnosticContext.superblocks_action_id,
    //     applicationId: this.uiDiagnosticContext.superblocks_application_id,
    //     pageId: this.uiDiagnosticContext.superblocks_page_id,
    //     ...properties,
    //   },
    // });
  }

  setUIDiagnosticContext(context: UIDiagnosticMetadata) {
    this.uiDiagnosticContext = {
      ...this.uiDiagnosticContext,
      ...context,
    };
    datadogLogs.setGlobalContext(this.uiDiagnosticContext);
  }

  getUIDiagnosticContext() {
    return this.uiDiagnosticContext;
  }
}

export default Logger.Instance;
