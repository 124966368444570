import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import * as Sentry from "@sentry/react";
import { EnvEnum, RegionEnum } from "@superblocksteam/shared";
import { isEmpty } from "lodash";
import { setLevel, LogLevelDesc } from "loglevel";
import React from "react";
import { onINP } from "web-vitals/attribution";
import envs from "env";
import { getGlobalConfigs } from "legacy/configs";
import AnalyticsUtil from "legacy/utils/AnalyticsUtil";
import localStorage from "legacy/utils/localStorage";
import { isDev } from "utils/env";

// 'prod-eu' was a valid value for SUPERBLOCKS_UI_ENV, but since we are cleaning it up to be just 'prod'.
// Since datadog dashboard, metrics, alerts already depend on this 'prod-eu', we will keep passing 'prod-eu' to ddog.
// 'prod-eu' was the only outlier that mixed env and region, other ENVs are dev, staging so ddog tags are fine.
const ddogEnv =
  envs.get("SUPERBLOCKS_UI_ENV") === EnvEnum.PROD &&
  envs.get("SUPERBLOCKS_UI_REGION") === RegionEnum.EU
    ? "prod-eu"
    : envs.get("SUPERBLOCKS_UI_ENV");

datadogLogs.init({
  site: envs.get("SUPERBLOCKS_UI_DATADOG_SITE"),
  clientToken: envs.get("SUPERBLOCKS_UI_DATADOG_CLIENT_TOKEN"),
  forwardErrorsToLogs: true,
  env: ddogEnv,
  service: "superblocks-browser",
  version: envs.get("SUPERBLOCKS_UI_VERSION"),
  proxy: isEmpty(envs.get("SUPERBLOCKS_UI_DATADOG_PROXY_URL"))
    ? null
    : envs.get("SUPERBLOCKS_UI_DATADOG_PROXY_URL"),
  // This is used to track the user's session across multiple domains, including iframe
  // must match rum setting
  useCrossSiteSessionCookie: true,
});
datadogLogs.logger.setHandler("http");

datadogRum.init({
  applicationId: envs.get("SUPERBLOCKS_UI_DATADOG_APPLICATION_ID"),
  clientToken: envs.get("SUPERBLOCKS_UI_DATADOG_CLIENT_TOKEN"),
  site: envs.get("SUPERBLOCKS_UI_DATADOG_SITE"),
  service: "superblocks-browser",
  env: envs.get("SUPERBLOCKS_UI_ENV"),
  version: envs.get("SUPERBLOCKS_UI_VERSION"),
  proxy: isEmpty(envs.get("SUPERBLOCKS_UI_DATADOG_PROXY_URL"))
    ? null
    : envs.get("SUPERBLOCKS_UI_DATADOG_PROXY_URL"),
  sessionSampleRate: Number(envs.get("SUPERBLOCKS_UI_DATADOG_RUM_SAMPLE_RATE")),
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  // This is used to track the user's session across multiple domains, including iframe
  // must match logger setting
  useCrossSiteSessionCookie: true,
  allowedTracingUrls: [
    "https://app.superblockshq.com",
    "https://staging.superblockshq.com",
    "https://app.superblocks.com",
    "https://staging.superblocks.com",
    "https://eu.superblocks.com",
    "https://api.staging.superblocks.com",
    "https://api.superblocks.com",
  ],
});
// This metric is not yet supported by the RUM SDK
onINP(
  (metric) => {
    return datadogRum.addAction("web-vitals.onINP", {
      ...metric,
      duration: metric.value,
    });
  },
  {
    reportAllChanges: true,
  },
);

// Enable Why Did You Render? only in ?sb_wdyr=true mode
if (isDev() && window.location.search.includes("sb_wdyr=true")) {
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React, {
    trackAllPureComponents: true,
    trackExtraHooks: [["useSelector"]],
    titleColor: "white",
    diffNameColor: "white",
    diffPathColor: "red",
  });
}

const superblocksConfigs = getGlobalConfigs();

if (superblocksConfigs.sentry.enabled) {
  Sentry.init({
    ...superblocksConfigs.sentry,
    beforeBreadcrumb(breadcrumb) {
      if (breadcrumb.category === "console" && breadcrumb.level !== "error") {
        return null;
      }
      if (breadcrumb.category === "sentry.transaction") {
        return null;
      }
      if (breadcrumb.category === "redux.action") {
        if (breadcrumb.data && breadcrumb.data.type === "SET_EVALUATED_TREE") {
          breadcrumb.data = undefined;
        }
      }
      return breadcrumb;
    },
  });
}

if (superblocksConfigs.smartLook.enabled) {
  const { id } = superblocksConfigs.smartLook;
  AnalyticsUtil.initializeSmartLook(id);
}

if (superblocksConfigs.segment.enabled) {
  if (superblocksConfigs.segment.apiKey) {
    // This value is only enabled for the cloud hosted version. It is not set in self-hosted environments
    AnalyticsUtil.initializeSegment(superblocksConfigs.segment.apiKey);
  } else if (superblocksConfigs.segment.ceKey) {
    // This value is set in self-hosted environments. But if the analytics are disabled, it's never used.
    AnalyticsUtil.initializeSegment(superblocksConfigs.segment.ceKey);
  }
}

const getEnvLogLevel = (configLevel: LogLevelDesc): LogLevelDesc => {
  let logLevel = configLevel;
  if (localStorage && localStorage.getItem) {
    const localStorageLevel = localStorage.getItem("logLevel") as LogLevelDesc;
    if (localStorageLevel) logLevel = localStorageLevel;
  }
  return logLevel;
};

setLevel(getEnvLogLevel(superblocksConfigs.logLevel));
